<template>
<div>
  <div id="facilitiesOuter">
    <div id="facilitiesInner">
      <div class="facilitiesColumn">
        <div class="facilitiesItem">
          <h3>Laser Equipment</h3>
          <ul>
            <li>1 x Laserdyne 790 JK704 60″x30″x46″</li>
            <li>1 x Laserdyne 780 Rofin 1700 39″x27″x27″</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Vertical Milling Equipment</h3>
          <ul>
            <li>1 x CNC YCM 34"x71" VMC</li>
            <li>1 x CNC Haas 16"x20" VF2</li>
            <li>2 x CNC Fadal 15"x20" VMC</li>
            <li>2 x CNC Fadal 40"x20" VMC</li>
            <li>1 x CNC Fadal 30"x60" VMC</li>
            <li>1 x CNC Haas 20"x50" VMC</li>
            <li>1 x CNC Haas 20"x30" VMC</li>
            <li>2 x CNC Haas Super Mini Mills</li>
            <li>1 x CNC Colt MC550 15"x20" VMC</li>
            <li>2 x S1-2 HP Bridgeports - 9"x42" Tables with A.G.E - Prototraks</li>
            <li>1 x S1-2 Bridgeport - 9"x42" Tables</li>
            <li>1 x J-Head Bridgeport Readouts - 9"48" Tables Two with Readouts</li>
            <li>1 x Comet Vari-drive 10” X 50 “ Table</li>
            <li>1 x CNC Haas VF-4SS 20” X 50” VMC</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Saws</h3>
          <ul>
            <li>1 x Marval Mark II</li>
            <li>1 x Everett Abrasive Cut-Off Machine</li>
            <li>1 x Do-All Vertical Band Saw 30"</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Jig Grinder</h3>
          <ul>
            <li>2 x 22"x44" Moore Fosdick</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Cylindrical Grinder</h3>
          <ul>
            <li>1 x Landis 10"x20" ID, OD</li>
            <li>1 x Universal Cylindrical Grinder</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Heat Treat Equipment</h3>
          <ul>
            <li>1 x Tempering Furnace</li>
            <li>1 x Norco 6"x6"x12" Furnace</li>
            <li>1 x Lucifer 12"x12"x20" Furnace</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Miscellaneous</h3>
          <ul>
            <li>2 x Sunnen Hone Machine</li>
            <li>1 x Hagen & Goebel Tapping Machine</li>
            <li>1 x Welder Airco Die/Stick 160</li>
            <li>1 x Thompson 150KVA Spot Welder</li>
            <li>1 x 30" JTL Comparator 10x, 20x, 50x Lens</li>
            <li>1 x Sweco 10 Cubic Foot Vibratory Finish</li>
            <li>1 x Barren Tumbler</li>
            <li>1 x Vibrating Tumbler</li>
            <li>1 x Caterpillar 8000lb Fork Truck</li>
            <li>1 x Hyster 6000lb Fork Truck</li>
            <li>1 x 5 Ton Dwight Foote Overhead Crane</li>
          </ul>
        </div>
      </div>




      <div class="facilitiesColumn">
        <div class="facilitiesItem">
          <h3>Wire EDM Equipment</h3>
          <ul>
            <li>1 x Accutex AU-300I</li>
            <li>2 x Fanuc Robocut Alpha-OC Wire Cut</li>
            <li>2 x Fanuc Alpha OIA Wire Cut</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Horizontal Milling Equipment</h3>
          <ul>
            <li>1 x Okuma & Howa Millac 40H 2-Pallet</li>
            <li>1 x Haas HS2 35"x38"x36" 2-Pallet</li>
            <li>1 x Okuma MA-500 2-Pallet</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Machine Attachments & Inspection</h3>
          <ul>
            <li>1 x Mitutoyo 910 CMM</li>
            <li>2 x Harig #1 Grind All</li>
            <li>2 x Harig #2 Grind All</li>
            <li>1 x Harig Radius Dresser</li>
            <li>1 x J&S Radius Dresser</li>
            <li>1 x Hermann Schmidt Magnet Sine</li>
            <li>1 x Hermann Schmidt Squaring BLK</li>
            <li>1 x 12"x12" Suburban 10" Sine</li>
            <li>1 x 8"x12" Suburban 10" Sine</li>
            <li>1 x 18" Heightolater B/S</li>
            <li>1 x 18" Cadillac Gauge</li>
            <li>1 x 20" Rotary Table P/W</li>
            <li>1 x 24" Tilt Rotary Table</li>
            <li>1 x 15" Rotary Table Bridgeport</li>
            <li>6 x Angle Lock Vises 6"</li>
            <li>2 x Double Anglelock Vises</li>
            <li>2 x Bridgeport Vises 6"</li>
            <li>1 x Palmgren Vise 8"</li>
            <li>1 x Hardness Tester (Supreme)</li>
            <li>1 x Wilson Hardness Tester</li>
            <li>1 x Frost Angle Setting Vise 6"</li>
            <li>1 x Diaform Dressier 10:1</li>
            <li>2 x 60" Rotary Tables P/W</li>
            <li>1 x 24" Tesa Hite</li>
            <li>1 x 48" Starret Master Setting Gauge</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Surface Grinders</h3>
          <ul>
            <li>2 x Brown & Sharpe 6"x18"</li>
            <li>1 x Brown & Sharpe 5"x10"</li>
            <li>1 x Brown & Sharpe 6"x12"</li>
            <li>1 x Do-All 5"x10"</li>
            <li>1 x Boyar Schultz 6"x12"</li>
            <li>1 x Norton 6"x18"</li>
            <li>1 x Okamoto 12"x14"</li>
            <li>1 x Blohm 14"x60"</li>
            <li>1 x Blanchard Grinder 42" Dia. #22</li>
            <li>1 x Mitsui 8"x18"</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Turning Equipment</h3>
          <ul>
            <li>1 x DMG Mori NLX 2500SY Twin SPD Y Axis 16"x28"</li>
            <li>1 x Mori Seiki ZL-45B Twin Turret</li>
            <li>1 x Swiss Nexturn</li>
            <li>1 x Okuma V100R 42" VTL</li>
            <li>1 x Republic Lathe 16"x40"</li>
            <li>1 x Mori Seiki SL-25M 14"x20"</li>
            <li>1 x Haas HL4 10" Chuck CNC Lathe</li>
            <li>1 x Mori Seiki SL-204 Twin Spindle</li>
            <li>1 x Mori Seiki NL2000 Twin Spindle</li>
            <li>1 x Topper - 100A CNC Lathe</li>
            <li>1 x Ganesh Lathe 20"x60"</li>
          </ul>
        </div>
      </div>


      <div class="facilitiesColumn">
        <div class="facilitiesItem">
          <h3>Vertical EDM Equipment</h3>
          <ul>
            <li>1 x Sodick EPOC-4 CNC EDM</li>
            <li>1 x Sodick EPOC-3 CNC EDM</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Drills</h3>
          <ul>
            <li>1 x Morris Mor Speed Radial Arm - 3' Arm 9" Column</li>
            <li>1 x Morris Mor Speed Radial Arm - 4' Arm 12" Column</li>
            <li>1 x Select 3' Arm Radial Drill</li>
            <li>1 x Rockwell Floor Model</li>
            <li>1 x Rockwell Bench Model</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Welding Equipment</h3>
          <ul>
            <li>1 x Millermatic 251 MIG Welder</li>
            <li>1 x Lincoln Precision TIG Welder</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Air Compressors</h3>
          <ul>
            <li>1 x 40HP Sullivan Palatek</li>
            <li>1 x 50HP Quincy QGS-50 Rotart</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Granite Layout Tables</h3>
          <ul>
            <li>Micro Flat 36"x60"</li>
            <li>Starrett 18"x24"</li>
            <li>Starrett 24"x36"</li>
            <li>Do-All 24"x36"</li>
            <li>Rock of Ages 36"x48"</li>
          </ul>
        </div>
        <div class="facilitiesItem">
          <h3>Press Room Equipment</h3>
          <ul>
            <li>1 x 500 Ton Farquar Forming Press</li>
            <li>1 x 300 Ton Straight Side Clearing Punch Press</li>
            <li>1 x 200 Ton Bliss with Cushion</li>
            <li>1 x 100 Ton Minister Straight Side</li>
            <li>1 x 50 Ton Wilson Arbor Press</li>
            <li>1 x 50 Ton National Hydraulics Arbor Press</li>
            <li>1 x 60 Ton Federal Punch Press</li>
            <li>1 x 45 Ton Federal Press</li>
            <li>1 x Coil Straightener .125x12.000</li>
            <li>1 x Coil Matic .090x18" Straightener & Feeder</li>
            <li>1 x Dallas .400x18" Feeder</li>
            <li>1 x Coil Matic .125 Thk x 12" Wide x 18" Feed Length</li>
            <li>3 x Coil Cradles</li>
            <li>1 x Stock Reel Rapid Air</li>
            <li>1 x Jasco Straigtener</li>
            <li>1 x Warddington Digital Roll Feed 18" Wide x .187 Thk</li>
            <li>1 x Whitney-Jensen Shear 1/8x4'</li>
            <li>1 x Coe Doil Straightener .312x18"</li>
            <li>1 x Cooper Weymouth .250x24" Roll Feed</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
  <contact></contact>
  </div>
</template>

<style>
  #facilitiesOuter {
    padding: 80px 24px;
    display: flex;
    position: relative;
    justify-content: center;
    background: linear-gradient(331deg, rgb(19, 79, 152) 4%, rgb(17, 19, 78) 50%, rgba(5,3,34,1) 79%);
    /* min-height: 100vh; */
    text-align: left;
  }

  #facilitiesInner {
    position: relative;
    padding: 24px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
  }

  .facilitiesColumn {
    flex: 1 0 33%;
    padding: 12px;
  }


 .facilitiesItem {
    position: relative;
    padding: 16px;
    margin-bottom: 24px;
    transition: 300ms ease-out;
    border-radius: 3px;
    background: linear-gradient(111deg, #fff, #fff);
    z-index: 0;
  }

  .facilitiesItem:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: linear-gradient(111deg, #dbd2f56a, #cae0ff5e);
    transition: 300ms ease-out;
    border-radius: 3px;
    opacity: 0;
  }

  .facilitiesItem:hover {
    transform: translateY(-12px);
  }

  .facilitiesItem:hover:before {
    opacity: 1;
    box-shadow: 0px 8px 35px rgba(53, 45, 111, 0.16);
  }

  .facilitiesItem__text {
    padding: 0 8px;
  }

  .facilitiesItem__text h3 {
    position: relative;
    margin-bottom: 20px;
  }

  .facilitiesItem ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
  }

  .facilitiesItem ul li {
    margin-bottom: 6px;
  }

  .facilitiesItem ul li:last-of-type {
    margin-bottom: 0;
  }
  @media screen and (max-width: 979px) {
    .facilitiesColumn {
      flex: 0 0 50%;
    }
  }

  @media screen and (max-width: 660px) {
    .facilitiesColumn {
      flex: 0 0 100%;
      padding: 0;
    }

    #facilitiesInner {
      padding: 24px 0;
    }
  }
</style>

<script>
import Contact from '@/components/Contact'

export default {
  components: {
    Contact
  }
}
</script>